<template>
  <v-list-item
    link
    @click="$emit('selectCategory', category)"
  >
    <v-list-item-avatar>
      <v-badge
        bottom
        right
        dot
      >
        <v-avatar v-if="thumbnail">
          <v-img
            :src="thumbnail"
          />
        </v-avatar>
        <v-avatar
          v-else
          color="grey"
          class="pr-2"
        >
          <v-icon
            color="white"
            class="pl-2"
          >
            mdi-sitemap
          </v-icon>
        </v-avatar>
      </v-badge>
    </v-list-item-avatar>
    <v-list-item-content :draggable="false">
      <v-list-item-title 
        v-if="category.name"
      >
        {{ category.name }}
      </v-list-item-title>
      <v-list-item-title 
        v-else
        class="red--text font-italic"
      >
        {{ $t('nameRequired') }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: 'CategoryListItem',
  props: {
    category: {
      type: Object, 
      default: () => {}
    },
    items: {
      type: Array, 
      default: () => []
    }
  },
	data: () => ({
		panelOpen: null
	}),
	watch: {
		//
	},
	computed: {
		itemsInEtags() {
			return this.items.filter(item => item.categories && item.categories.length && item.categories.some(c => c.name === 'eTags'))
    }, 
    thumbnail() {
      if(this.category.image && this.category.image && this.category.image.file) {
        return this.category.image.file.sources.thumbnail
      }
      return null
    }
	}
}
</script>
