<template>
  <div>
    <div v-if="categories === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="categories !== null">
      <v-list>
        <v-divider />
        <draggable
          :list="categories"
          handle=".drag-handle"
          :move="checkMove"
        >
          <template v-for="(category, index) in categories">
            <CategoryListItem
              :key="category.uuid"
              :category="category"
              :items="itemsInCategory(category)"
              @selectCategory="$emit('selectCategory', category)"
            />
            <v-divider :key="index" />
          </template>
        </draggable>
      </v-list>
    </div>
  </div>
</template>

<script>

import CategoryListItem from "../components/CategoryListItem"
import draggable from 'vuedraggable'

export default {
	name: 'CategoryList',
	props: {
		categories: {
			type: Array, 
			default: () => []
		}
	},
	components: {
		CategoryListItem,
		draggable
	},
	data: () => ({
		startIndex: 0,
		isLoadingMore: false,
		length: 50,
		step: 50,
		testList: ['test 1', 'test2', 'test3']
	}),
	mounted() {
		//
	},
	computed: {
		itemsList() {
			return this.$store.state.items
		}
	},
	methods: {
		checkMove(e) {
			console.log("Future index: " + e.draggedContext.futureIndex)
		},
		itemsInCategory(category) {
			return this.itemsList.filter(item => item.categories && item.categories.length && item.categories.some(c => c.uuid === category.uuid))
		},
		loadMore: function() {
			this.isLoadingMore = true
			this.length = this.length + this.step
		}
	}
}
</script>
